@import "src/styles/settings";

#about-me {
  padding-top: 1rem;

  img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 2px $primaryColor, 0 0 10px $primaryColor, 0 0 20px $primaryColor;
    border-radius: 2rem;
    order: 2;
  }

  .about-me-title {
    order: 1;
    font-family: $titleFont;
    font-size: 2.5rem;
    padding: 0.5rem;
  }

  p {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}
