@import "src/styles/settings";

#labor-information {
  .labor-information-section {
    color: $primaryColor;
    text-align: center;
    padding: 0.5rem;

    @media only screen and (min-width: $tabletMinWidth) {
      margin-top: 1rem;
    }

    h2 {
      font-size: 1rem;
      text-transform: uppercase;

      @media only screen and (min-width: $tabletMinWidth) {
        font-size: 2rem;
      }
    }
  }
}
