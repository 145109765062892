@import "src/styles/settings";

#achievements {
  .achievements-picture > div {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    @media only screen and (min-width: $desktopMinWidth) {
      justify-content: space-around;
    }

    .picture {
      width: 65%;
      height: 65%;
      flex: 0 0 auto;
      box-shadow: 0 0 2px $primaryColor, 0 0 10px $primaryColor, 0 0 20px $primaryColor;
      margin: 2rem 1.3rem 2rem 1.3rem;
      border-radius: 4rem;

      .mr-5 {
        margin-left: 5rem;
        width: 200px;
      }

      @media only screen and (min-width: $tabletMinWidth) {
        width: 40%;
        height: 40%;
      }

      @media only screen and (min-width: $desktopMinWidth) {
        width: 20%;
        height: 20%;
      }
    }
  }
}
