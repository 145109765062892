@import "./settings";
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-size: calc(.825rem + 0.3125*(100vw - 20rem) / 55);
  color: $primaryColor;
  background-color: $backgroundColor;

  .container-fluid {
    background-color: $backgroundColor;
  }
  section {
    margin-top: 2em;
  }
}
