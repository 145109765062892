@import "src/styles/settings";

@keyframes downArrow {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

header {
  background-image: url("./../../../assets/header.jpg");
  background-size: cover;
  background-position: center top;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    color: white;
    font-size: 12vw;
    font-family: $titleFont;
  }

  .sub-title {
    color: white;
    font-size: 10vw;
    font-family: $titleFont;
  }

  .down-arrow {
    position: absolute;
    cursor: pointer;
    bottom: 8%;
    left: 50%;
    width: 5vh;
    height: 5vh;
    margin-left: -2.5vh;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb05 1.5s infinite;
    animation: downArrow 1.5s infinite;
    box-sizing: border-box;
  }
}
