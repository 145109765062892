@import "src/styles/settings";

#my-rates {
  table {
    border-collapse: collapse;

    td {
      padding: 0.5em;
    }

    .new-table-section td {
      padding: 0;

      hr {
        color: $primaryColor;
        opacity: 1;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }
}
