@import "src/styles/settings";

footer {
  padding: 1rem 0 1rem 0;
  background-color: $primaryColor;
  color: white;

  .footer-section-title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  ul {
    margin-block-start: 0 !important;
    padding-inline-start: 0;
    list-style: none;

    li {
      a {
        &:hover{
          color: inherit;
          text-decoration: underline;
        }
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .leaflet-container {
    width: 100%;
    height: 20rem;

    .leaflet-control-attribution {
      display: none;
    }
  }
}
